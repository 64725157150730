import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import './participation.css';

const Participation = () => {
  return (
    <div className="giswnauce__participation">
        <div className="giswnauce__participation-container">
            <span className="anchor-participation" id="giswnauce_participation"></span>
            <div className="participation-container_header">
                <p><FontAwesomeIcon icon={faUserPlus} /></p><h1>Uczestnictwo</h1>
            </div>
            <div className="participation-container_subheader">
              <a href="https://forms.pg.edu.pl/01HPEN1GTAFGTJZVE2K5SXHAES" target="_blank" rel="noreferrer"><h3>
                Formularz zgłoszeniowy
              </h3></a>
            </div>
            <div className="participation-container_description">
              <p>Organizatorzy nie zapewniają noclegu.<br /></p>
              <p>Opłatę za konferencję należy uiścić w&nbsp;terminie maksymalnie 2&nbsp;tygodni od momentu rejestracji.</p>
              <p>W celu zwolnienia z podatku należy wypełnić <a href="https://drive.pg.edu.pl/s/WaBsxJwNhIp3GGE"><u>OŚWIADCZENIE</u></a> ← kliknij, aby pobrać<br /></p>
              <p>Pomocny w wypełnieniu oświadczenia może być <a href="https://drive.pg.edu.pl/s/A5m2tE8A2w7M1pe"><u>WZÓR</u></a> ← kliknij, aby pobrać</p>
              <hr />
              <p>Opłaty konferencyjnej należy dokonać na konto:</p>
              <h2>Fundacja Rozwoju Inżynierii Lądowej</h2>
              <h3>
                Regon 191903857<br />
                NIP 957-07-54-108<br />
                Bank Pekao S.A. O/Gdańsk<br />
                52 1240 5400 1111 0000 4915 0353<br />
                W&nbsp;tytule przelewu prosimy wpisać „GIS W NAUCE” imię i&nbsp;nazwisko osoby/osób dla której/których dokonywana jest opłata konferencyjna.
              </h3>
            </div>
        </div> 
    </div>
  )
}

export default Participation