import React from 'react';
import KomunikatI from '../../assets/GISwNauce_Komunikat_I.pdf';
import KomunikatII from '../../assets/GISwNauce_Komunikat_II.pdf';

import './main.css';

const Main = () => {
  return (
    <div className="giswnauce__main">
      <span className="anchor-main" id="giswnauce_main"></span>
      <div className="giswnauce__main-content">
        <div className="content-description">
          <h1>XI Edycja Ogólnopolskiej Konferencji GIS w&nbsp;nauce</h1>
          <h2>Szanowni Państwo!</h2>
          <p>
          Serdecznie zapraszamy do udziału w&nbsp;XI edycji Ogólnopolskiej Konferencji <b>GIS w&nbsp;Nauce</b>,
          która odbędzie się w&nbsp;dniach <b>26&nbsp;-&nbsp;27 września 2024 roku</b> na terenie <b>Politechniki Gdańskiej</b>.
          Konferencja, organizowana przez polskie ośrodki akademickie specjalizujące się w&nbsp;dziedzinie systemów
          informacji geograficznej, geoinformacji i&nbsp;geoinformatyki, stanowi unikalne forum wymiany idei i&nbsp;doświadczeń
          związanych z&nbsp;zastosowaniem systemów informacji geograficznej we wszystkich dziedzinach nauki. 
          </p>
          <p>
          Celem głównym Konferencji jest prezentacja najnowszych osiągnięć naukowych w&nbsp;obszarze systemów informacji
          geograficznej oraz prowadzenie dyskusji na temat różnorodnych zastosowań tych systemów w&nbsp;naukach ścisłych
          i&nbsp;humanistycznych. Wydarzenie to oferuje doskonałą okazję do spotkania się przedstawicieli środowiska
          naukowego, wymiany poglądów oraz nawiązania współpracy.
          </p>
          <p>
          Konferencja obejmuje sesje plenarne, referaty tematyczne, sesje posterowe oraz warsztaty praktyczne w&nbsp;laboratoriach
          komputerowych. Tegoroczna edycja, kontynuując tradycję organizowania Konferencji co roku, przenosi się tym razem
          do Gdańska, miasta o&nbsp;bogatej historii i&nbsp;innowacyjnym podejściu do technologii.
          </p>
          <p>
          Pierwsza Konferencja <b>GIS w&nbsp;Nauce</b>, której inicjatorami byli prof.&nbsp;Iwona Jażdżewska i&nbsp;prof.&nbsp;Jacek Urbański,
          miała miejsce w&nbsp;2012 roku na Uniwersytecie Łódzkim. Od tamtej pory cieszy się rosnącym zainteresowaniem środowiska
          naukowego. Zapraszamy wszystkich zainteresowanych do udziału w tej prestiżowej konferencji, gdzie będą mieli Państwo
          okazję do prezentacji swoich osiągnięć, wymiany wiedzy oraz nawiązania nowych kontaktów naukowych.
          </p>
          <p>
          Zachęcamy do śledzenia informacji na naszej stronie internetowej oraz do rejestracji swego uczestnictwa. Mamy nadzieję,
          że spotkamy się licznie w Gdańsku, na <b>Politechnice Gdańskiej</b>, aby wspólnie kontynuować tradycję Konferencji <b>GIS w Nauce</b> i&nbsp;przyczynić
          się do rozwoju nauki związanej z systemami informacji geograficznej.
          </p>
          <a href={ KomunikatI } target="_blank" rel="noreferrer"><h3>
          KOMUNIKAT I
          </h3></a>
          <a href={ KomunikatII } target="_blank" rel="noreferrer"><h3>
          KOMUNIKAT II
          </h3></a>
        </div>
      </div>
    </div>
  )
}

export default Main