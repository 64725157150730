/* eslint-disable eqeqeq */

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AbstractsContext from '../../AbstractsContext';
import { Spinner } from '../../components';

import './abstracts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDown } from '@fortawesome/free-regular-svg-icons';

const Abstracts = () => {
  const handleScroll = () => {
    const position = window.scrollY;
    let elem = document.getElementsByClassName("sticky-navbar")[0];
    let rect = elem.getBoundingClientRect().top;
    if(position > (rect + window.scrollY)) {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.add("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts")[0].classList.add("scroll-margin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-absolute");
      }
    }
    else {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.remove("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts")[0].classList.remove("scroll-margin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-absolute");
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlepostersScroll = (event) => {
    event.preventDefault(); // Zapobiegamy domyślnemu przewinięciu natychmiastowemu

    const anchor = document.querySelector("#anchor-posters");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  };

const tiles = useContext(AbstractsContext);
const [loading, setLoading] = useState(true);
const [showSpinner, setShowSpinner] = useState(true);
const [liveTiles, setLiveTiles] = useState([]);

useEffect(() => {
  if (showSpinner) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
  
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, [showSpinner]);

/*useEffect(() => {
  const timer = setTimeout(() => {
    setShowSpinner(false);
  }, 100);
  
  return () => clearTimeout(timer);
}, []);*/

useEffect(() => {
  if (!tiles || tiles.length < 54) {
    setLoading(true);
    setShowSpinner(true);
  }
}, [tiles]);

useEffect(() => {
  if (tiles && tiles.length >= 54) {
    setLoading(false);
    setShowSpinner(false);
  }
}, [tiles]);

useEffect(() => {
  function updateLiveTiles() {
      const now = new Date();
      const updatedLiveTiles = tiles.filter(tile => {
          const tileDate = new Date(tile.date);
          const startTime = new Date(tileDate.setHours(...tile.startTime.split(':')));
          const endTime = new Date(tileDate.setHours(...tile.endTime.split(':')));

          return now >= startTime && now <= endTime;
      });

      setLiveTiles(updatedLiveTiles);
  }

  updateLiveTiles();

  const intervalId = setInterval(updateLiveTiles, 60000);
  return () => clearInterval(intervalId);
}, [tiles]);

if (showSpinner || loading) {
  return <div className="giswnauce__abstracts"><Spinner /></div>;
}

if (!tiles) {
  return <p>File not found</p>;
}

  return (
    <div className="giswnauce__abstracts">
      {/* Kafelki live */}
      <div className="live-content-two-rows">
        <div className="live-two-rows-container">
          <h1>Sala 1</h1>
          <div className="live-content_element-container">
            {liveTiles
              .filter(tile => tile.room == 1)
              .map(tile => (
              <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                <div className="content_element live">
                  <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                  <h3>{tile.speakerAffiliation}</h3>
                  <p>{tile.titlepl}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="live-two-rows-container">
          <h1>Sala 2</h1>
          <div className="live-content_element-container">
            {liveTiles
              .filter(tile => tile.room == 2)
              .map(tile => (
              <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                <div className="content_element live">
                  <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                  <h3>{tile.speakerAffiliation}</h3>
                  <p>{tile.titlepl}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_header">
        <h1>Czwartek, 26 września</h1>
      </div>
      <div className="abstracts-container_subheader">
        <h2>10:00-11:00 — Uroczyste rozpoczęcie i&nbsp;wykład inauguracyjny</h2>
      </div>
      <div className="giswnauce__abstracts-content-1">
        <div className="content_element-1">
          <h2>10:00-10:30 — Ewa Wojciechowska, Dominika Wróblewska, Adam Inglot</h2>
          <h3>Politechnika Gdańska</h3>
          <p>Uroczyste rozpoczęcie, prezentacja otwierająca konferencję GIS w Nauce.</p>
        </div>
        {/* Kafelek prezentacja otwierająca */}
        <div className="content-one-row">
          <div className="two-rows-container">
            <div className="giswnauce__abstracts-content">
              {tiles
                .filter(tile => tile.room == 1 && tile.date == "2024-09-26" && tile.startTime >= "10:30" && tile.endTime <= "11:00")
                .sort((a, b) => a.startTime.localeCompare(b.startTime))
                .map(tile => (
                  <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                    <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                      <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                      <h3>{tile.speakerAffiliation}</h3>
                      <p>{tile.titlepl}</p>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>11:00-11:20 — Wykład Partnera głównego - ESRI Polska</h2>
      </div>
      <div className="giswnauce__abstracts-content-1">
        <div className="content_element-1">
          <h2>11:00-11:20 — Paulina Gajownik Mućka</h2>
          <h3>ESRI Polska</h3>
          <p>Prezentacja sponsora XI Edycji Konferencji GIS w Nauce</p>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>11:20-12:00 — Przerwa kawowa / Sesja posterów</h2>
        <a href="#anchor-posters" onClick={handlepostersScroll}><h3><span><FontAwesomeIcon icon={faCircleDown}/></span>Przejdź do listy posterów<span><FontAwesomeIcon icon={faCircleDown}/></span></h3></a>
      </div>
      <div className="abstracts-container_subheader">
        <h2>12:00-14:00 — Sesja referatów I — "Środowisko"</h2>
        <h4>Prowadzący sesję: Piotr Wężyk</h4>
      </div>
      {/* Kafelki Sesja referatów I */}
      <div className="content-one-row">
        <div className="two-rows-container">
          <div className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room == 1 && tile.date == "2024-09-26" && tile.startTime >= "12:00" && tile.endTime <= "14:00")
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                    <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>14:15-15:00 — Przerwa obiadowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>15:00-17:00 — Sesja referatów II — "Planowanie Przestrzenne, Miasto"</h2>
        <h4>Prowadząca sesję: Iwona Jażdżewska</h4>
      </div>
      {/* Kafelki Sesja referatów II */}
      <div className="content-one-row">
        <div className="two-rows-container">
          <div className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room == 1 && tile.date == "2024-09-26" && tile.startTime >= "15:00" && tile.endTime <= "17:00")
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                    <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>19:00-22:00 — Uroczysta kolacja</h2>
      </div>
      <div className="abstracts-container_header">
        <h1>Piątek, 27 września</h1>
      </div>
      <div className="abstracts-container_subheader">
        <h2>10:30-12:15 — Sesja referatów IIIa / IIIb</h2>
        <h4>Prowadzący sesje: Michał Rzeszewski / Leszek Gawrysiak</h4>
      </div>
      {/* Kafelki Sesja referatów III */}
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1<br />"Technologia i&nbsp;Teledetekcja"</h1>
          <div className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room == 1 && tile.date == "2024-09-27" && tile.startTime >= "10:30" && tile.endTime <= "12:15")
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                    <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2<br />"Gospodarka Wodna, Lasy, Usługi Ekosystemowe"</h1>
          <div className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room == 2 && tile.date == "2024-09-27" && tile.startTime >= "10:30" && tile.endTime <= "12:15")
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                    <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>12:30-13:30 — Przerwa kawowa / Sesja posterów</h2>
        <a href="#anchor-posters" onClick={handlepostersScroll}><h3><span><FontAwesomeIcon icon={faCircleDown}/></span>Przejdź do listy posterów<span><FontAwesomeIcon icon={faCircleDown}/></span></h3></a>
      </div>
      <div className="abstracts-container_subheader">
        <h2>13:30-14:30 — Debata GIS w Nauce</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>14:30-15:30 — Przerwa obiadowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>15:30-17:00 — Warsztaty</h2>
      </div>
      {/* Kafelki Warsztaty */}
      <div className="content-one-row">
        <div className="two-rows-container">
          <div className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room == 1 && tile.date == "2024-09-27" && tile.startTime >= "15:30" && tile.endTime <= "17:00")
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}>
                    <h2>{tile.startTime}-{tile.endTime} — {tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_header">
        <span className="anchor-posters" id="anchor-posters"></span>
        <h1>Lista posterów</h1>
      </div>
      {/* Kafelki postery */}
      <div className="content-one-row">
        <div className="two-rows-container">
          <div className="giswnauce__abstracts-content-poster">
            {tiles
              .filter(tile => tile.room == 3)
              .sort((a, b) => a.startTime.localeCompare(b.startTime))
              .map(tile => (
                <Link key={tile.id + 'day1'} to={`/giswnauce-abstracts/${tile.id}`}>
                  <div className="content_element">
                    <h2>{tile.speakerName} {tile.speakerSurname}</h2>
                    <h3>{tile.speakerAffiliation}</h3>
                    <p>{tile.titlepl}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Abstracts