import React from 'react';
import BackgroundGISWNAUCE from '../../assets/Background_final.webp';

import './header.css';

const Header = () => {
  return (
    <div className="giswnauce__header">
        <div className="giswnauce__header-content">
          <a href='/'><img src={BackgroundGISWNAUCE} alt="Banner GIS W NAUCE" /></a>
        </div>
        <span className="sticky-navbar" />
    </div>
  )
}

export default Header