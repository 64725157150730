import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

import './place.css';

const Place = () => {
  return (
    <div className="giswnauce__place">
        <div className="giswnauce__place-container">
            <span className="anchor-place" id="giswnauce_place"></span>
            <div className="place-container_header">
                <p><FontAwesomeIcon icon={faMapLocationDot} /></p><h1>Miejsce</h1>
            </div>
            <div className="place-container_subheader">
                <h2>Miejsce konferencji</h2>
            </div>
            <div className="place-container_description">
                <h3>Politechnika Gdańska<br/>
                Centrum Informatyczne TASK (CI TASK)<br/>
                ul. Traugutta 75<br/>
                80-221 Gdańsk</h3>
                <iframe title="CI TASK on google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1162.1229821068348!2d18.61737509555002!3d54.37030438206778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd74905f2a2c3d%3A0x9905532cec81cde2!2sCentrum%20Informatyczne%20TASK%20(CI%20TASK)!5e0!3m2!1spl!2spl!4v1710506299436!5m2!1spl!2spl" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="place-container_subheader filharmonia">
                <h2>Miejsce uroczystej kolacji</h2>
            </div>
            <div className="place-container_description">
                <h3>Filharmonia Bałtycka, im. Fryderyka Chopina<br/>
                ul. Ołowianka 1<br/>
                80-751 Gdańsk</h3>
                <iframe title="CI TASK on google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2325.259158674242!2d18.657433477016905!3d54.352401298974414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd730a5848484b%3A0x7ed697180aa35a0!2sPolska%20Filharmonia%20Ba%C5%82tycka%20im.%20Fryderyka%20Chopina%20w%20Gda%C5%84sku!5e0!3m2!1spl!2spl!4v1718876346312!5m2!1spl!2spl" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div> 
    </div>
  )
}

export default Place