import React from 'react';

import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {
  return (
    <div className="giswnauce__footer">
        <p>Copyright <FontAwesomeIcon icon={ faCopyright }/> 2024 Jakub Jabłoński. All rights reserved.</p>
    </div>
  )
}

export default Footer