import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './contact.css';

const Contact = () => {
  return (
    <div className="giswnauce__contact">
        <div className="giswnauce__contact-container">
            <span className="anchor-contact" id="giswnauce_contact"></span>
            <div className="contact-container_header">
                <p><FontAwesomeIcon icon={faAddressBook} /></p><h1>Kontakt</h1>
            </div>
            <div className="contact-container_subheader">
                <h2>Przewodniczący Komitetu Organizacyjnego</h2>
            </div>
            <div className="contact-container_description">
                <h3>Dr inż. Adam Inglot</h3>
                <a href="mailto:adam.inglot@pg.edu.pl" target="_blank" rel="noreferrer"><h3><span><FontAwesomeIcon icon={faEnvelope} /></span>adam.inglot@pg.edu.pl</h3></a>
            </div>
            <div className="contact-container_subheader">
                <h2>Multimedia / Pomoc techniczna</h2>
            </div>
            <div className="contact-container_description">
                <h3>Inż. Jakub Jabłoński</h3>
                <a href="mailto:jakub.jablonski@pg.edu.pl" target="_blank" rel="noreferrer"><h3><span><FontAwesomeIcon icon={faEnvelope} /></span>jakub.jablonski@pg.edu.pl</h3></a>
            </div>
        </div> 
    </div>
  )
}

export default Contact