import React from 'react';
import LogoKG from '../../assets/LogotypKG.webp';
import EsriPolska from '../../assets/Esri_Polska.png';
import LogoGUS1 from '../../assets/LogotypGUS1.webp';

import './right.css';

const Right = () => {
  return (
    <div className="giswnauce__right">
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Organizator</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo" href="https://wilis.pg.edu.pl/kg" target="_blank" rel="noreferrer"><img src={ LogoKG } alt="Katedra Geodezji" /></a>
            </div>
        </div>
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Partner główny</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo-esri" href="https://www.esri.pl" target="_blank" rel="noreferrer"><img src={ EsriPolska } alt="Esri Polska" /></a>
            </div>
        </div>
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header-GUS">
                <h1>Organizator<br />warsztatów</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo-gus" href="https://portal.geo.stat.gov.pl/" target="_blank" rel="noreferrer"><img src={ LogoGUS1 } alt="GUS Portal Geostatystyczny" /></a>
            </div>
        </div>
    </div>
  )
}

export default Right