import React, { useState, useEffect } from 'react';
import './cookiesBanner.css';

const CookieBanner = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleConsent = (decision) => {
    localStorage.setItem('cookieConsent', decision);
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="cookie-banner">
      <p className="cookie-message">
        <strong>Ta strona wykorzystuje pliki cookie</strong><br />Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszej strony.<br />Czy zgadzasz się na używanie cookies?
      </p>
      <button className="cookie-button-accept" onClick={() => handleConsent('accepted')}>Zgadzam się</button>
      <button className="cookie-button-reject" onClick={() => handleConsent('declined')}>Nie zgadzam się</button>
    </div>
  );
};

export default CookieBanner;
