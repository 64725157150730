import React, { createContext, useState, useEffect } from 'react';
import Papa from 'papaparse';

const AbstractsContext = createContext();

export const AbstractsProvider = ({ children }) => {
  const [tiles, setTiles] = useState([]);

  /*useEffect(() => {
    const loadCSV = async () => {
      const response = await fetch('/referaty.csv');
      const reader = response.body.getReader();
      const result = await reader.read();
      const text = new TextDecoder().decode(result.value);

      Papa.parse(text, {
        header: true,
        delimiter: ';',
        complete: (results) => {
          const formattedData = results.data.map(row => ({
            id: row.id,
            room: row.room,
            date: row.date || '2000-01-01',
            startTime: row.startTime || '00:00',
            endTime: row.endTime || '23:59',
            speakerName: row.speakers_name,
            speakerSurname: row.speakers_surname,
            speakerAffiliation: row.speakers_affiliation,
            name1: row.name1,
            name2: row.name2,
            name3: row.name3,
            name4: row.name4,
            name5: row.name5,
            name6: row.name6,
            name7: row.name7,
            name8: row.name8,
            name9: row.name9,
            affiliation1: row.affiliation1,
            affiliation2: row.affiliation2,
            affiliation3: row.affiliation3,
            affiliation4: row.affiliation4,
            email: row.email,
            titlepl: row.titlePL,
            keypl: row.keyPL,
            abstractpl: row.abstractPL,
            titleen: row.titleEN,
            keyen: row.keyEN,
            abstracten: row.abstractEN,
            funding: row.funding
          }));
          setTiles(formattedData);
        }
      });
    };

    loadCSV();
  }, []);*/

  useEffect(() => {
    const loadCSV = async () => {
      try {
        const response = await fetch('/referaty.csv');
        const text = await response.text(); // Odczytuje całą odpowiedź jako tekst

        Papa.parse(text, {
          header: true,
          delimiter: ';',
          complete: (results) => {
            const formattedData = results.data.map(row => ({
              id: row.id,
              room: row.room,
              date: row.date || '2000-01-01',
              startTime: row.startTime || '00:00',
              endTime: row.endTime || '23:59',
              speakerName: row.speakers_name,
              speakerSurname: row.speakers_surname,
              speakerAffiliation: row.speakers_affiliation,
              name1: row.name1,
              name2: row.name2,
              name3: row.name3,
              name4: row.name4,
              name5: row.name5,
              name6: row.name6,
              name7: row.name7,
              name8: row.name8,
              name9: row.name9,
              affiliation1: row.affiliation1,
              affiliation2: row.affiliation2,
              affiliation3: row.affiliation3,
              affiliation4: row.affiliation4,
              email: row.email,
              titlepl: row.titlePL,
              keypl: row.keyPL,
              abstractpl: row.abstractPL,
              titleen: row.titleEN,
              keyen: row.keyEN,
              abstracten: row.abstractEN,
              funding: row.funding
            }));
            setTiles(formattedData);
          },
          error: (error) => {
            console.error('Błąd parsowania CSV:', error);
          }
        });
      } catch (error) {
        console.error('Błąd podczas ładowania pliku CSV:', error);
      }
    };

    loadCSV();
  }, []);

  return (
    <AbstractsContext.Provider value={tiles}>
      {children}
    </AbstractsContext.Provider>
  );
};

export default AbstractsContext;