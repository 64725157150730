import React from 'react';

import './spinner.css';

const Spinner = () => {
  return (
    <div className="giswnauce__spinner">
      <div className="spinner"></div>
    </div>
  );
}

export default Spinner;